import React, {useMemo} from "react";
import PlainButton from "../ui/PlainButton";
import TextButton from "../ui/TextButton";
import XCol from "../ui/XCol";

const getOrientation = (file) =>
  // adapted from https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
  new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var view = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xffd8) {
        return resolve(null);
      }
      var length = view.byteLength,
        offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return resolve(null);
        var marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return resolve(null);
          }

          var little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;
          for (var i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(null);
    };
    reader.readAsArrayBuffer(file);
  });

const clampDimensions = ({width, height, maxDim}) => {
  if (width > maxDim && width > height) {
    return {
      width: maxDim,
      height: Math.round((maxDim / width) * height),
    };
  } else if (height > maxDim) {
    return {
      width: Math.round((maxDim / height) * width),
      height: maxDim,
    };
  } else {
    return {width, height};
  }
};

const processOrientation = (orientation, ctx, canvas) => {
  const {width, height} = canvas;
  if (orientation > 4) {
    canvas.width = height;
    canvas.height = width;
  }
  switch (orientation) {
    case 2:
      // horizontal flip
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      break;
    case 3:
      // 180° rotate left
      ctx.translate(canvas.width, canvas.height);
      ctx.rotate(Math.PI);
      break;
    case 4:
      // vertical flip
      ctx.translate(0, canvas.height);
      ctx.scale(1, -1);
      break;
    case 5:
      // vertical flip + 90 rotate right
      ctx.rotate(0.5 * Math.PI);
      ctx.scale(1, -1);
      break;
    case 6:
      // 90° rotate right
      ctx.rotate(0.5 * Math.PI);
      ctx.translate(0, -canvas.width);
      break;
    case 7:
      // horizontal flip + 90 rotate right
      ctx.rotate(0.5 * Math.PI);
      ctx.translate(canvas.width, -canvas.height);
      ctx.scale(-1, 1);
      break;
    case 8:
      // 90° rotate left
      ctx.rotate(-0.5 * Math.PI);
      ctx.translate(-canvas.width, 0);
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn("Unknown orientation", orientation);
  }
};

const loadFileAsImage = (file) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = URL.createObjectURL(file);
    img.onerror = reject;
  });

export const putImageOnCanvas = (file, maxDim) =>
  loadFileAsImage(file).then((img) => {
    const {width, height} = clampDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight,
      maxDim,
    });

    const imgCanvas = document.createElement("canvas");
    const imgContext = imgCanvas.getContext("2d");

    imgCanvas.width = width;
    imgCanvas.height = height;

    return getOrientation(file).then((orientation) => {
      if (orientation !== null) {
        processOrientation(orientation, imgContext, imgCanvas);
      }
      imgContext.drawImage(img, 0, 0, width, height);
      imgContext.restore();
      return {imgCanvas, imgContext};
    });
  });

const storeImage = (file) =>
  putImageOnCanvas(file, 800).then(({imgCanvas}) => {
    const dataUrl = imgCanvas.toDataURL("image/jpeg", 0.5).split(",")[1];
    const binStr = atob(dataUrl);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (var i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    const blob = new Blob([arr], {type: "image/jpeg"});
    return {blob, width: imgCanvas.width, height: imgCanvas.height};
  });

export const blobToArrayBuffer = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      resolve(reader.result);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(blob);
  });

export const useAsObjectUrl = (file) => {
  const {blob, type} = file || {blob: null, type: null};
  return useMemo(() => blob && type && arrayBufferBlobToObjectURL(blob, type), [blob, type]);
};

export const arrayBufferBlobToObjectURL = (buffer, type) => {
  const blob = new Blob([buffer], {type: type});
  return URL.createObjectURL(blob);
};

const UploadButton = ({onChange}) => {
  const handleImage = (e) => {
    const file = (e.target.files || [])[0];
    if (file) {
      storeImage(file).then(({blob, width, height}) => {
        blobToArrayBuffer(blob).then((buff) => {
          const fileInfo = {
            type: blob.type,
            blob: buff,
            bytesOnDevice: blob.size,
            width,
            height,
          };
          return onChange(fileInfo);
        });
      });
    }
  };

  return (
    <XCol align="start">
      <PlainButton as="label">
        <input
          type="file"
          accept="image/*"
          capture
          style={{display: "none"}}
          onChange={handleImage}
        />
        Foto schießen
      </PlainButton>
    </XCol>
  );
};

const Photo = ({value, onDelete}) => {
  const objectUrl = useAsObjectUrl(value);

  return (
    <XCol align="start">
      <XCol relative>
        <img
          src={objectUrl}
          width={value.width}
          height={value.height}
          alt=""
          style={{height: "7rem", width: "auto", display: "block"}}
        />
        <XCol absolute style={{top: 5, right: 5}}>
          <TextButton onClick={onDelete}>X</TextButton>
        </XCol>
      </XCol>
    </XCol>
  );
};

const PhotoInput = ({value, onChange}) =>
  value ? (
    <Photo value={value} onDelete={() => onChange(null)} />
  ) : (
    <UploadButton onChange={onChange} />
  );

export default PhotoInput;
