import React from "react";
import LinkOrButton from "./LinkOrButton";
import {useButtonBehaviour} from "./ButtonBehaviour";
import {rawButtonStyle, textButtonStyles} from "./button.treat";
import cx from "../cx";
import uiClasses from "./ui.treat";

const TextButton = React.forwardRef(
  ({color = "default", size = "md", active, square, className, ...rest}, ref) => {
    const buttonProps = useButtonBehaviour(rest);
    return (
      <LinkOrButton
        ref={ref}
        className={cx(
          rawButtonStyle,
          textButtonStyles.default,
          uiClasses.textTypes.heading,
          rest.disabled && textButtonStyles.disabled,
          textButtonStyles.colors[color],
          textButtonStyles.sizes[size],
          square && textButtonStyles.squarePadding[size],
          className
        )}
        {...buttonProps}
      />
    );
  }
);

export default TextButton;
